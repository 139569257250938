.project-timesheet-container {
    mat-form-field {
        height: 50px;
        width: 350px;
        border-radius: 14px;
        overflow: hidden;
        background: var(--backgroundSecondary);
        border: 1px solid var(--textBlueBright);
    }

    .mat-placeholder-required {
        color: var(--textMain);
    }

    .mat-input-element:disabled {
        color: var(--textMain);
    }

    .mat-form-field-flex {
        margin: 0;
        height: 50px !important;
    }

    .mat-form-field-infix {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        border: none;
        padding: 3px 0 0 5px !important;
    }

    .mat-form-field-underline {
        display: none !important;
    }
}
