.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
    background-color: $steelblue !important;
}

.mat-checkbox-frame,
.mat-checkbox-background {
    width: 16px;
    height: 16px;
    border-radius: 50% !important;
    background-color: $light-purple !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--textBlueBright) !important;
}

.mat-checkbox-background svg {
    display: none;
}

.label {
    color: var(--textMain);
    font-size: 16px;
}
