/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/angular-calendar/scss/angular-calendar.scss';
@import '../../node_modules/rfs/scss';

@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import './material-overrides.scss';
@import './variables.scss';
@import './checkbox.scss';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
    height: 100%;
    background-color: var(--backgroundMain);
    box-sizing: border-box;
}

body.dark {
    @include dark();
    @include font();
    @include deviceSize();
}
body.light {
    @include light();
    @include font();
    @include deviceSize();
}

.cdk-global-scrollblock {
    position: initial;
    width: initial;
    overflow: hidden;
}

.cdk-global-overlay-wrapper {
    overflow: auto;
    text-align: center;
}

.cdk-overlay-pane {
    display: inline-block;
    text-align: left;
}

body {
    font-family: $myriad-regular;
    background-color: var(--backgroundSecondary);
    overflow: visible;
    min-height: fit-content;
    height: 100%;
    color: var(--textMain);

    // TODO: Refactor these to separate SCSS files and import them at the top.
    // Currently all SCSS files are in the root of the project, please create
    // some folder structure that is more maintainable.
    .landing-page {
        background-color: var(--backgroundMain);
        margin-bottom: 150px;
        min-height: fit-content;

        .name {
            color: $white;
            @include padding(5rem);
            @include font-size(4rem);
        }

        .main-container {
            height: 90%;
        }

        .form-container {
            @include padding(2rem);
        }

        .back-to-menu {
            text-decoration: none;
        }
    }

    mat-form-field.ng-invalid.ng-touched {
        animation: invalid-field-entrance;
        animation-duration: 0.3s;
        margin-bottom: 20px;
    }

    @keyframes invalid-field-entrance {
        from {
            margin-bottom: 0;
        }
        to {
            margin-bottom: 20px;
        }
    }
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--backgroundMain);
}

::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-purple;
}

.mat-snack-bar-container {
    background-color: var(--backgroundPopup);
    color: var(--textPopup);
}

.mat-snack-bar-container .mat-button {
    color: var(--textPopup);
}

.mat-progress-spinner {
    circle {
        stroke: $blue;
    }
}

.main-page-title {
    font-family: $inter-semibold;
    color: var(--textMain);
    font-size: 32px;
    margin-bottom: 42px;
}

.mat-calendar-table-header-divider::after {
    background-color: var(--calendarBorder);
}

.mat-calendar-body-selected {
    background-color: var(--backgroundCalendarSelected) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--backgroundCalendarHover);
}

.page-title {
    font-family: $myriad-semibold;
    color: var(--textMain);
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.dialog-panel,
.document-editor-dialog {
    .mat-dialog-container {
        background-color: transparent;
    }
}

.dialog-panel {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 50%;
    @media screen and (max-width: 1680px) {
        width: 70%;
    }
}

.large-dialog {
    height: 70% !important;
    box-shadow: $box-shadow 0px 4px 12px;
    margin-left: 100px;
}

.mat-dialog-container {
    padding: 0 !important;
    border-radius: 12px !important;
    box-shadow: 0 0 5px $dark-shadow;
}

.search-bar-container {
    width: 30vw;
    max-width: 350px;
}

// TODO: remove fonts from assets as well if new design doesn't require them.
@font-face {
    font-family: 'Myriadpro-Black';
    src:
        local('Myriadpro-Blac'),
        url('../assets/fonts/myriadpro-black.otf') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriadpro-Bold';
    src:
        local('FSMillbankWeb-Italic'),
        url('../assets/fonts/MyriadPro-Bold.otf') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Myriadpro-BoldCond';
    src:
        local('Myriadpro-BoldCond'),
        url('../assets/fonts/MyriadPro-BoldCond.otf') format('woff');
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'Myriadpro-BoldCondIt';
    src:
        local('Myriadpro-BoldCondIt'),
        url('../assets/fonts/MyriadPro-BoldCondIt.otf') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Myriadpro-BoldIt';
    src:
        local('Myriadpro-BoldIt'),
        url('../assets/fonts/MyriadPro-BoldIt.otf') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Myriadpro-LightIt';
    src:
        local('Myriadpro-LightIt'),
        url('../assets/fonts/MyriadPro-LightIt.otf') format('woff');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'Myriadpro-Regular';
    src:
        local('Myriadpro-Regular'),
        url('../assets/fonts/MyriadPro-Regular.otf') format('woff');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'Myriadpro-Semibold';
    src:
        local('Myriadpro-Semibold'),
        url('../assets/fonts/MyriadPro-Semibold.otf') format('woff');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'Myriadpro-SemiboldIt';
    src:
        local('Myriadpro-SemiboldIt'),
        url('../assets/fonts/MyriadPro-SemiboldIt.otf') format('woff');
    font-weight: bolder;
    font-style: italic;
}

@font-face {
    font-family: 'Inter-Regular';
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/Inter-Regular.otf') format('woff');
}

@font-face {
    font-family: 'Inter-Bold';
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/Inter-Bold.otf') format('woff');
}

@font-face {
    font-family: 'Inter-SemiBold';
    font-weight: 600;
    font-style: normal;
    src: url('../assets/fonts/Inter-SemiBold.otf') format('woff');
}

@font-face {
    font-family: 'Inter-Medium';
    font-weight: 500;
    font-style: normal;
    src: url('../assets/fonts/Inter-Medium.otf') format('woff');
}

.mat-expansion-panel-content {
    .mat-expansion-panel-body {
        padding: 0;
    }
}

.basic-other-container .mat-expansion-indicator::after {
    border: none;
}

div.cdk-overlay-container {
    div.backdropBackground {
        background-color: var(--backgroundMain);
        opacity: 0.9;
    }
}

.mat-tab-nav-bar,
.mat-tab-header {
    box-shadow: inset 0px -3px 0px var(--lightBorder);
}

.mat-datepicker-content {
    background-color: var(--backgroundSecondary);
    color: var(--textMain);

    td,
    th {
        color: var(--textLighter);
    }

    button {
        color: var(--textMain) !important;
    }

    .mat-calendar-arrow {
        fill: var(--textMain) !important;
    }

    .mat-calendar-body-cell-content {
        color: var(--textMain);
    }

    .mat-calendar-body-cell.mat-calendar-body-disabled.national-holiday > .mat-calendar-body-cell-content {
        color: $badge-color !important;
    }

    .mat-calendar-body-disabled
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
            .mat-calendar-body-comparison-identical
        ) {
        color: var(--textLightest);
    }

    .mat-calendar-body-disabled
        > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        border-color: var(--lightBorder);
    }
}

.mat-select-panel {
    background-color: var(--backgroundThree);

    box-shadow:
        0px 2px 4px -1px var(--shadowOne),
        0px 4px 5px 0px var(--shadowTwo),
        0px 1px 10px 0px var(--shadowThree) !important;

    .mat-option-text {
        color: var(--textMain);
    }
}

.mat-drawer-container {
    color: var(--textMain);
}

.mat-drawer-inner-container {
    overflow: hidden !important;
}

.language-select-panel {
    margin-top: 2.5rem !important;
}

.alert {
    color: var(--alertColor);
    background-color: var(--alertBackgroundColor);
}

.cdk-overlay-dark-backdrop {
    backdrop-filter: blur(5px) grayscale(75%);
    background-repeat: no-repeat;
    width: 100%;
}
