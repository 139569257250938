@import 'src/styles/variables';
@import 'src/styles/material/material-dialog-override.scss';
@import 'src/styles/material/material-project-form-container-override.scss';
@import 'src/styles/material/material-project-timesheet-container-override.scss';

mat-label {
    color: var(--textMain);
}

.grid-container {
    mat-form-field {
        color: var(--textMain) !important;
        width: 90% !important;
    }
}

.mat-dialog-container {
    background-color: var(--backgroundSecondary) !important;
}

.mat-autocomplete-panel {
    background-color: var(--backgroundThree);
}

.mat-checkbox-label,
.mat-select-arrow,
.mat-tab-label,
.mat-option,
.mat-dialog-container {
    color: var(--textMain) !important;
}

.mat-ink-bar {
    background-color: var(--textContrast) !important;
}

.mat-ink-bar-override {
    transition: 0s !important;
}

.mat-tab-label[aria-selected='true'] {
    font-weight: bold;
    color: var(--textContrast) !important;
    opacity: unset;
}

.mat-option:hover {
    background-color: var(--backgroundThreeSelectedHover) !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: var(--backgroundThreeSelected) !important;
}
