// Colors

//Blue colors
$steelblue: #4583b5;
$main-blue: #0e1b6b;
$blue: #206ea7;
$darkblue-background: #253d69;
$sidenav-lightblue: #859bc9;
$sidenav-darkblue: #101c6c;
$sidenav-darker: #041833;
$bright-blue-light: #d4dafa;
$component-background: #141f3d;
$lightblue: #3e9be9;
$profileblue: #19274a;
$darkblue: #151e3d;
$bright-blue: #162aaa;
$dialog-blue: #040664;
$middarkblue: #1a274a;
$transparentblue: #536892;
$month-changer-hover: #c8dbff4d;
$caret-light: #8392ea;
$darkest-blue-background: #020b26;
$dark-blue-background: #031227;
$dark-blue-secondary: #101f37;
$skyblue: #0877bc;
$waterblue: #3d9be9;
$arrowblue: #7d92b6;
$caret: #3f51b5;
$dark-form-background: #151f3d;
$input-blue: #1b274a;
$light-purple: #a5b0d9;
$blue-haze: #cbcfe2;
$calendar-blue: #1b59f8;
$intern-blue: #438ecc;
$corporate-blue: #8386b4;
$inactive-blue: #6774c8;
$light-blue-text: #d6e9ff;
$dark-mode-main-blue: #439de6;
$dark-mode-light-blue: #b6d2f5;
$dark-bluish-grey: #373a4e;
$calendar-background-dark: #0a1a32;

//White - gray- black
$purewhite: #ffffff;
$dark-gray: #000000de;
$text-color: #0f0f0f;
$white: #f2f2f2;
$dark-shadow: #00000075;
$light-shadow: #1f1f1f75;
$light-grey-background: #eff2f9;
$text-gray: #71717a;
$light-grey: #d4d4d8;
$scrollbar-shadow: #0000004d;
$logo-loading-gray: #ffffffb3;
$shift-calendar-gray: #64646433;
$active-card-background: #ebeeff;
$soulless-gray: #cfcfcf;
$light-gray: #e2e2e2;
$mid-gray: #808080;
$manatee: #8a8fac;
$black: #000000;
$bluish-grey: #898dab;
$notification-button-border: #ebe8e8;
$notification-date-color: #979393;
$dark-text: #27272a;
$calendar-border-lines: #7e818c80;
$box-shadow: #0000001a;
$calendar-header: #4d4d4d;
$month-changer-hover-dark: #193456;

//Reds
$pink-red: #e83d5d;
$badge-color: #ff0000;
$bright-red: #f9473a;
$national-holiday: #f30707;
$holiday-declined: #ffd6c9;
$shift-red: #f6b499;
$alert-pink: #f1aeb5;
$alert-purple: #58151c;

//Greens
$holiday-approved: #e0ffcc;
$shift-green: #a9ce96;
$holiday-green-dark: #96ce97;

//Other
$holiday-pending: #fff8b8;
$sun-yellow: #fcc010;
$holiday-pending-dark: #ffde84;

//Color mixins

@mixin light() {
    --backgroundMain: #{$light-grey-background};
    --backgroundSecondary: #{$purewhite};
    --backgroundThree: #{$purewhite};
    --backgroundThreeSelected: #{rgba(0, 0, 0, 0.12)};
    --backgroundThreeSelectedHover: #{rgba(0, 0, 0, 0.05)};
    --calendarHover: #{$month-changer-hover};
    --backgroundBlue: #{$sidenav-darkblue};
    --backgroundMainBlue: #{$main-blue};
    --backgroundTableHeader: #{$light-grey};
    --backgroundLogoLoading: #{$logo-loading-gray};
    --backgroundToggle: #{$sidenav-lightblue};
    --backgroundUserCard: #{$purewhite};
    --backgroundPopup: #{$middarkblue};
    --backgroundDisabledInput: #{rgba($light-purple, 0.6)};
    --backgroundDisabledButton: #{$light-grey};
    --backgroundCalendarSelected: #{$caret};
    --backgroundCalendarHover: #{rgba(63, 81, 181, 0.3)};
    --backgroundCalendar: #{$purewhite};

    --backgroundSkyBlue: #{$skyblue};
    --backgroundWaterBlue: #{$waterblue};

    --colorMain: #{$white};
    --colorArrowBlue: #{$arrowblue};

    --border-today-calendar: #{$mid-gray};

    --performance-chart-blue: #{$intern-blue};
    --performance-chart-highlight-blue: #{#1c4261c7};
    --performance-sort-background: #{$purewhite};
    --performance-option-hover-background: #{$light-grey-background};

    --textMain: #{$text-color};
    --textOpposite: #{$purewhite};
    --textContrast: #{$bright-blue};
    --textWorklog: #{$text-color};
    --textBlue: #{$main-blue};
    --textBlueBright: #{$bright-blue};
    --textBlueBleak: #{$transparentblue};
    --textCaret: #{$caret};
    --textMid: #{$calendar-header};
    --textLighter: #{$notification-date-color};
    --textMidLight: #{$dark-text};
    --textLightest: #{$text-gray};
    --textUserCard: #{$dark-text};
    --textPopup: #{$purewhite};
    --textCorporate: #{$corporate-blue};
    --textCalendarHeader: #{$calendar-header};
    --textWeekend: #{$light-gray};
    --textCalendarDay: #{$calendar-blue};
    --textCalendarSelectedDay: #{$calendar-blue};
    --textCalendarBorderedDay: #{$calendar-blue};

    --lightBorder: #{$light-gray};
    --tableBorder: #{$component-background};
    --coworkerBorder: #{$text-color};
    --calendarBorder: #{$light-gray};
    --shiftRequestBorder: #{$calendar-border-lines};
    --secondaryTableBorder: #{$light-gray};

    --alertColor: #{$alert-purple};
    --alertBackgroundColor: #{$alert-pink};

    --shadowOne: #{rgba(0, 0, 0, 0.2)};
    --shadowTwo: #{rgba(0, 0, 0, 0.14)};
    --shadowThree: #{rgba(0, 0, 0, 0.12)};
    --shadowFour: #{$dark-shadow};

    --approvedGreen: #{$holiday-approved};
    --pendingYellow: #{$holiday-pending};
    --declinedRed: #{$holiday-declined};
    --shiftGreen: #{$shift-green};

    --error: #{$bright-red};
}

@mixin dark() {
    --backgroundMain: #{$dark-blue-background};
    --backgroundSecondary: #{$dark-blue-secondary};
    --backgroundThree: #{$dark-blue-background};
    --backgroundThreeSelected: #{#2a5f91};
    --backgroundThreeSelectedHover: #{$month-changer-hover-dark};
    --calendarHover: #{$month-changer-hover-dark};
    --backgroundBlue: #{$sidenav-darker};
    --backgroundMainBlue: #{$bright-blue};
    --backgroundTableHeader: #{$dark-blue-background};
    --backgroundLogoLoading: #{$dark-blue-background};
    --backgroundToggle: #{$darkblue-background};
    --backgroundUserCard: #{$middarkblue};
    --backgroundPopup: #{$dark-mode-light-blue};
    --backgroundDisabledInput: #{rgba($dark-bluish-grey, 0.7)};
    --backgroundDisabledButton: #{$dark-bluish-grey, };
    --backgroundCalendarSelected: #{rgba($dark-mode-main-blue, 0.5)};
    --backgroundCalendarHover: #{rgba($dark-mode-main-blue, 0.15)};
    --backgroundCalendar: #{$calendar-background-dark};
    --backgroundSkyBlue: #{$skyblue};
    --backgroundWaterBlue: #{$waterblue};

    --colorMain: #{$light-shadow};
    --colorArrowBlue: #{$arrowblue};

    --border-today-calendar: #{$blue-haze};

    --performance-chart-blue: #{#1a6bad};
    --performance-chart-highlight-blue: #{$lightblue};
    --performance-sort-background: #{$dark-blue-secondary};
    --performance-option-hover-background: #{#2a5f91};

    --textMain: #{#e6e6e6};
    --textOpposite: #{$text-color};
    --textContrast: #{$light-blue-text};
    --textWorklog: #{$light-blue-text};
    --textBlue: #{$bright-blue-light};
    --textBlueBright: #{$dark-mode-main-blue};
    --textBlueBleak: #{$sidenav-lightblue};
    --textCaret: #{$dark-mode-light-blue};
    --textMid: #{$transparentblue};
    --textLighter: #{$soulless-gray};
    --textMidLight: #{$soulless-gray};
    --textLightest: #{$bluish-grey};
    --textUserCard: #{$purewhite};
    --textPopup: #{$middarkblue};
    --textCorporate: #{$light-blue-text};
    --textCalendarHeader: #{$dark-mode-main-blue};
    --textWeekend: #{#565c73};
    --textCalendarDay: #{$dark-mode-main-blue};
    --textCalendarSelectedDay: #{$light-blue-text};
    --textCalendarBorderedDay: #{$calendar-background-dark};

    --lightBorder: #{$blue-haze};
    --tableBorder: #{$blue-haze};
    --coworkerBorder: #{$dark-blue-secondary};
    --calendarBorder: #{#4c7daf};
    --shiftRequestBorder: #{$dark-mode-main-blue};
    --secondaryTableBorder: #{$darkblue-background};

    --alertColor: #{$alert-pink};
    --alertBackgroundColor: #{transparent};

    --shadowOne: #{rgba(31, 31, 31, 0.2)};
    --shadowTwo: #{rgba(31, 31, 31, 0.14)};
    --shadowThree: #{rgba(31, 31, 31, 0.12)};
    --shadowFour: #{$light-shadow};

    --approvedGreen: #{$holiday-green-dark};
    --pendingYellow: #{$holiday-pending-dark};
    --declinedRed: #{$shift-red};
    --shiftGreen: #{$holiday-green-dark};

    --error: #{$bright-red};
}

// Fonts

$myriad-regular: 'Myriadpro-Regular';
$myriad-semibold: 'Myriadpro-Semibold';
$myriad-black: 'Myriadpro-Black';
$myriad-bold: 'Myriadpro-Bold';
$myriad-boldcond: 'Myriadpro-BoldCond';
$myriad-boldcondit: 'Myriadpro-BoldCondIt';
$myriad-boldit: 'Myriadpro-BoldIt';
$myriad-lightit: 'Myriadpro-LightIt';
$myriad-light: 'Myriadpro-Light';
$myriad-semiboldit: 'Myriadpro-SemiboldIt';
$inter-semibold: 'Inter-SemiBold';
$inter-regular: 'Inter-Regular';
$inter-medium: 'Inter-Medium';
$inter-bold: 'Inter-Bold';

@mixin font() {
  --myriadRegular: #{$myriad-regular};
  --myriadSemiBold: #{$myriad-semibold};
  --myriadBlack: #{$myriad-black};
  --myriadBold: #{$myriad-bold};
  --myriadBoldCond: #{$myriad-boldcond};
  --myriadBoldCondit: #{$myriad-boldcondit};
  --myriadBoldit: #{$myriad-boldit};
  --myriadLightit: #{$myriad-light};
  --myriadLight: #{$myriad-lightit};
  --myriadSemiboldit: #{$myriad-semiboldit};
  --interSemibold: #{$inter-semibold};
  --interRegular: #{$inter-regular};
  --interMedium: #{$inter-medium};
  --interBold: #{$inter-bold};
}

// Sizes

$medium-device-max-width: 1650px;
$small-device-max-width: 1300px;
$smaller-than-tablet-width: 760px;
$shifts-max-width: 1424px;

@mixin deviceSize() {
  --mediumDeviceMaxWidth: #{$medium-device-max-width};
  --smallDeviceMaxWidth: #{$small-device-max-width};
  --smallerThanTabletWidth: #{$smaller-than-tablet-width};
  --shiftsMaxWidth: #{$shifts-max-width};
}

:root {
    --performance-chart-blue: #438ecc;
    --performance-chart-highlight-blue: #1c4261c7;
}
