.dialog {
    mat-form-field {
        width: 100%;
        padding-right: 20px;
        .mat-placeholder-required {
            color: $transparentblue;
        }
        mat-label {
            padding-top: 5px;
            color: $transparentblue;
        }
        label {
            padding-top: 5px;
        }
        label.mat-empty {
            padding-top: 0;
        }
    }

    mat-form-field.mat-focused {
        label {
            padding-top: 5px;
        }
    }
}

.fte-dialog > mat-dialog-container {
    background: $component-background;
}