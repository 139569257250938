.project-form-container {
    .form-column {
        mat-form-field {
            color: var(--textMain) !important;
            margin-bottom: 7px !important;
            width: 100% !important;
        }

        .mat-form-field-label {
            color: var(--textMain);
        }

        mat-form-field .mat-input-element,
        .mat-select {
            font-family: $myriad-bold;
        }

        .date-picker mat-label {
            margin-left: 10px;
        }

        .mat-form-field-type-mat-select mat-label {
            margin-left: 10px;
        }

        .column-one {
            .fte-container {
                .fte-input {
                    mat-form-field {
                        width: 100px;
                        height: 40px;
                        margin-bottom: 0;
                    }

                    mat-form-field .mat-form-field-infix {
                        padding: 0;
                    }
                }

                mat-form-field .mat-form-field-infix .mat-input-element {
                    text-align: center;
                }
            }
        }
    }
}
